/* TODO: revisar si hay estilos que no son necesarios eg: gridContent */
.sizeNumber {
  padding: 2% 5%;
  cursor: pointer;
  color: var(--color-black);
  transition: unset;
}
.sizeNumber:not(.sizeNotAvailable):hover {
  color: var(--color-black);
  text-decoration: underline;
}
.sizeNotAvailable {
  color: #919191;
  background: url('/assets-new/del-line.svg') center center no-repeat;
}
.sizeNotAvailable:hover {
  cursor: not-allowed;
}
.marquee {
  display: flex;
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-20% + var(--offset));
  --move-final: calc(-40% + var(--offset));
  align-self: end;
  background: #f6f6f7;
  color: #115b6e;
  font-size: 0.75em;
  grid-column: 1;
  grid-row: 1;
  padding: 5px 0;
  text-transform: uppercase;
}
.marqueeInner {
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  animation-play-state: running;
}
.marquee span {
  padding: 0 20px;
}
@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
.gridPhoto {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  /* border-right: 1px solid #D1CFD2; */
}
body[data-domain='nnormal'] .gridPhoto,
body[data-domain='nnormal'] .gridPhoto p,
body[data-domain='nnormal'] .gridPhoto img,
body[data-domain='nnormal'] .gridPhoto a {
  height: 100%;
}
body[data-domain='nnormal'] .gridPhoto img {
  object-fit: cover;
}
.gridImages {
  display: grid;
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  padding-bottom: 150%;
  background: #e8e7ea;
}
.sizes {
  align-self: end;
  display: grid;
  font-size: 0.875em;
  grid-gap: 8px 18px;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
  margin: 24px;
  grid-column: 1;
  grid-row: 1;
  z-index: -1;
}
.marqueeContainer {
  align-self: end;
  background: #f6f6f7;
  color: #115b6e;
  font-size: 0.75em;
  grid-column: 1;
  grid-row: 1;
  padding: 5px 0;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
}
.tierImg {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
}
.imageHover {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  visibility: hidden;
}
.visible {
  z-index: 2;
  visibility: visible;
}
.gridContent {
  width: 100%;
  margin: 16px 16px 40px 16px;
  flex-direction: column;
  background-color: white;
  position: relative;
}
body[data-domain='nnormal'] .gridContent {
  margin: 1rem 1rem 1rem 1rem;
}
.camperlab {
  background-color: var(--background);
}
.name {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0px;
  font-size: 0.875em;
}
.price {
  color: var(--color-black);
  font-size: 0.875em;
  margin-bottom: 0px;
}
.discountText {
  color: var(--color-disabled-primary);
  margin-bottom: 0px;
  font-size: 0.75em;
}
.discount {
  text-transform: uppercase;
  margin-left: 5px;
}
.itemInfo {
  color: var(--color-black);
  font-size: 0.5em;
  margin-top: 14px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.gridTierPhoto {
  position: relative;
}
.tierWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tierWrapper > div > a {
  height: 100%;
}
/* TODO: Pedir a patterson que quite estas reglas */
.tierWrapper :global .tier__media--video {
  transform: none !important;
  left: unset !important;
  top: unset !important;
}
/* .customizeContainer {
  position: absolute;
  top: 0px;
  z-index: 1;
  left: 0px;
  color: var(--color-black);
}
.customizeContainer > p {
  font-size: 0.5em;
  padding: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
} */

.pattersonTier[data-square='true'],
.pattersonTier[data-square='true'] .gridTierPhoto,
.pattersonTier[data-square='true'] a,
.pattersonTier[data-square='true'] .tierWrapper,
.pattersonTier[data-square='true'] .tierWrapper > *:first-child {
  height: max-content;
}

.pattersonTier[data-square='true'] picture {
  overflow: hidden;
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
  filter: none;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.pattersonTier[data-square='true'] .tierImg {
  height: max-content;
  min-height: 0px;
}

.pattersonTier[data-square='true'] :global .tier__media {
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
