.listWrapper {
  font-size: 0.875em;
}
.listWrapper :global .ant-col > .ant-list-item {
  margin-bottom: 10px;
}
.listWrapper :global div.ant-list-item > span {
  margin-right: 8px;
}
.optionLabel {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
}
.optionLabel .few {
  font-size: 12px;
}
@media(max-width: 767.5px) {
  .listWrapper :global .ant-list-items {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(6, minmax(30px, 1fr));
    margin-top: 24px;
  }
  .listWrapper :global .ant-list-item {
    padding: 0;
    border-bottom: unset;
    display: contents;
  }
}
@media(min-width: 768px) {
  .listWrapper :global .ant-list-items {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, minmax(30px, 1fr));
  }
  .listWrapper :global .ant-list-item {
    display: contents;
  }
}
@media(min-width: 1024px) {
  .listWrapper :global .ant-list-items {
    grid-gap: 4px;
    grid-template-columns: repeat(8, minmax(25px, 1fr));
    display: grid;
  }
  .listWrapper :global .ant-list-item {
    display: contents;
  }
}
.sizeTag {
  font-size: 0.875em;
  margin-right: 0px;
  line-height: 29px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(min-width: 1024px) {
  .sizeTag {
    font-size: 0.75em;
    line-height: 27px;
  }
}
.sizeTag:not(.sizeTagSelected) {
  background-color: var(--color-white);
  border: 2px solid var(--color-divider);
}
.sizeTagSelected {
  background-color: var(--color-black);
  border: 2px solid var(--color-black);
}
.sizeTagSelected:hover {
  border: 2px solid var(--color-black) !important;
}
.limitedSizeTag:not(.sizeTagSelected) {
  color: var(--color-error);
}
.limitedSizeTag:not(.sizeTagSelected):hover {
  color: var(--color-error) !important;
}
.sizeTag:hover {
  /*box-shadow: 0px 0px 1px 1px rgba(153, 153, 153, 1);*/
  border: 2px solid rgba(153, 153, 153, 1);
}
.sizeTag.sizeNotAvailable {
  color: var(--color-disabled-primary);
  background: url("/assets-new/del-line.svg") center center no-repeat;
}
.sizeTag.sizeNotAvailable:hover {
  border-color: var(--color-grey-light);
  color: var(--color-disabled-primary) !important;
  background-color: var(--color-withe);
}

.notifyMeForSize:not(.sizeTagSelected) {
  color: #999;
  background-color: var(--color-white);
  border: 2px solid rgba(153, 153, 153, 0.25);
}

.notifyMeForSize:not(.sizeTagSelected):hover {
  color: #999 !important;
  box-shadow: none;
  border: 2px solid rgba(153, 153, 153, 1) !important;
}

.notifyMeForSize.sizeTagSelected {
  background-color: var(--color-text-secondary);
}
.notifyMeForSize.sizeTagSelected {
  border: 2px solid rgba(153, 153, 153, 1) !important;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sizeTagSelected.sizeNotAvailable {
  background: none;
  background-color: var(--color-black);
}

.tooltip :global .ant-tooltip-inner {
  background-color: #DFDFDF;
  color: black;
  padding: 0px 6px;
  min-height: 17px;
  font-size: var(--description-size);
}
.tooltip :global .ant-tooltip-arrow-content {
  background-color: #DFDFDF;
  width: 10px;
  height: 10px;
}
.listWrapperSocks :global .ant-list-items{
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, minmax(30px, 1fr));
}
.listWrapperSocks :global .ant-list-items {
  font-size: 1em;
}
.listWrapperSocks .sizeTag {
  font-size: 0.875em!important;
}
@media(max-width: 1023.5px) {
  .listWrapperSocks .sizeTag {
    font-size: 1em!important;
  }
}

.sizeSelect {
  width: 100%;
}
.sizeSelect :global .ant-select-selector {
  border-color: var(--color-black) !important;
}
.sizeSelect :global .ant-select-arrow,
.sizeSelect :global .ant-select-selection-placeholder {
  color: var(--color-black) !important;
}


.sizeSelectDropdown :global .ant-select-item-option-content,
.sizeSelect :global .ant-select-selection-item {
  font-size: 13px !important;
}

.sizeTag {
  position: relative;
}

.bell {
  background-color: var(--color-white);
  position: absolute;
  right: -6px;
  scale: 0.9;
  top: -3px;
}

.template-columns-1 :global .ant-list-items {
  grid-template-columns: repeat(1, minmax(30px, 1fr));;
}

.template-columns-2 :global .ant-list-items {
  grid-template-columns: repeat(2, minmax(30px, 1fr));;
}

.template-columns-3 :global .ant-list-items {
  grid-template-columns: repeat(3, minmax(30px, 1fr));;
}

.template-columns-4 :global .ant-list-items {
  grid-template-columns: repeat(4, minmax(30px, 1fr));;
}

.template-columns-5 :global .ant-list-items {
  grid-template-columns: repeat(5, minmax(30px, 1fr));;
}

.template-columns-6 :global .ant-list-items {
  grid-template-columns: repeat(6, minmax(30px, 1fr));;
}

.customTemplate :global .ant-list-items {
  margin-top: 0px;
}