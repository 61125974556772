.modalButton {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalContent > div > h3 {
  margin: 0;
  margin-bottom: 1em;
  margin-left: 24px;
}

.modalContent :global .itemInfo {
  padding: 0;
}

.modalContent :global .gridImages {
  padding-bottom: 100%;
}

.modalContent :global .slick-slide > div {
  margin: 0;
  margin-right: 16px;
}

.modalWrapper :global .ant-modal-header {
  padding-bottom: 6px;
}

.modalContent :global .discountText, .modalContent :global p.name {
  display: flex;
  flex-wrap: nowrap;
}

.modalContent :global .oldShooting  {
  box-shadow: none;
}

.modalColors {
  margin-bottom: 1em;
}

.modalContent :global .gridContent {
  margin-left: 0;
}

.modalContent :global .specialProductMessage {
  display: none;
}

@media(max-width: 448px) {
  .modalWrapper :global .ant-modal {
    max-width: 100vw;
    margin: 0;
    min-width: 100vw;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    min-height: 100vh;
  }
  .modalWrapper :global .ant-modal-content {
    min-height: 100vh;
  }
}

body[data-domain="nnormal"] .modalButton {
  display: none;
}

.modalWrapper :global .ant-modal-body {
  padding: 24px 0px;
}

.modalContent :global .slick-slide:first-child {
  margin-left: 24px;
}

.modalContent :global .slick-track {
  display: flex;
}