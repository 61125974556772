.productsSlider {
  max-width: 100vw;
}

.productsSlider :global .slick-arrow {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 48px);
  transform: translateY(-50%);
  color: transparent;
  background-color: white;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-width: 0px;
  z-index: 200;
  background-image: url(/assets-new/chevron.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.productsSlider :global .slick-arrow:hover {
  background-color: white;
  background-image: url(/assets-new/chevron.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 200;
}

.productsSlider :global .slick-prev:before,
.productsSlider :global .slick-next:before {
  content: '';
}

.productsSlider :global .slick-disabled {
  display: none !important;
}

.productsSlider :global .slick-arrow:first-child {
  background-color: white;
  background-image: url(/assets-new/chevron.svg);
  background-repeat: no-repeat;
  background-position: center;
  left: 25px;
  transform: rotate(90deg);
}

.productsSlider :global .slick-arrow:last-child {
  background-color: white;
  background-image: url(/assets-new/chevron.svg);
  background-repeat: no-repeat;
  background-position: center;
  right: 25px;
  transform: rotate(-90deg);
}

.productsSlider :global .slick-track {
  margin-left: 0;
}

body[data-domain='camperlab'] .productsSlider :global .slick-slide {
  padding: 0 10px;
}

body[data-domain='camperlab'] .productsSlider :global .slick-arrow {
  background-color: transparent;
  width: 14px;
  height: 14px;
  border-radius: 0;
  box-shadow: none;
  top: calc(50% - 14px);
  transform: translateY(-50%);
}

body[data-domain='camperlab'] .productsSlider :global .slick-arrow,
body[data-domain='camperlab'] .productsSlider :global .slick-arrow:hover,
body[data-domain='camperlab'] .productsSlider :global .slick-arrow:first-child,
body[data-domain='camperlab'] .productsSlider :global .slick-arrow:last-child {
  background-image: url(/assets-new/svg/camperlab/chevron-camperlab.svg);
}

body[data-domain='camperlab'] .productsSlider :global .slick-arrow:first-child {
  left: 10px;
  transform: rotate(180deg);
}
body[data-domain='camperlab'] .productsSlider :global .slick-arrow:last-child {
  right: 10px;
  transform: none;
}
